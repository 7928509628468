/* Blog List Styles */
.blog-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px;
}

.blog-list-container ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Space between the cards */
  width: 100%;
}

.blog-list-container li {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 280px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden; /* Ensures the border-radius is respected */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.blog-list-container li:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-list-container li h3 {
  font-size: 1.6rem;
  margin: 0;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}

.blog-list-container li div {
  margin-top: 10px;
  color: #777;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.blog-list-container li small {
  font-size: 12px;
  color: #aaa;
  display: block;
  margin-top: 10px;
  text-align: right;
  font-weight: 300;
}

/* Blog detail styles */
.blog-detail-container {
  width: 100%;
  max-width: 1000px;
  margin: 30px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.blog-detail-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.blog-detail-container h2 {
  font-size: 2.4rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 700;
}

.blog-detail-container .content {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 20px;
}

.blog-detail-container img {
  width: 100%;
  max-width: 90%;
  height: auto;
  border-radius: 12px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.blog-detail-container p {
  font-size: 1rem;
  color: #777;
  margin-top: 15px;
  text-align: right;
}

.blog-detail-container button {
  background-color: #4CAF50;
  color: #fff;
  font-size: 1rem;
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: background-color 0.3s, transform 0.3s ease;
}

.blog-detail-container button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.blog-detail-container small {
  font-size: 0.9rem;
  color: #999;
  text-align: right;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .blog-list-container {
    flex-direction: column;
    align-items: center;
  }

  .blog-list-container li {
    width: 100%;
    max-width: 500px;
  }

  .blog-detail-container {
    padding: 20px;
  }

  .blog-detail-container h2 {
    font-size: 2rem;
  }

  .blog-detail-container .content {
    font-size: 1.1rem;
  }

  .blog-detail-container button {
    width: 100%;
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .blog-detail-container h2 {
    font-size: 1.8rem;
  }

  .blog-detail-container .content {
    font-size: 1rem;
  }

  .blog-detail-container button {
    font-size: 1rem;
  }
}
